/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-07-21 16:15:32
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-07-31 09:41:48
 * @FilePath: \bookmark\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import MainPage from "./bookmark/MainPage.vue"
import PageHead from "./bookmark/Mode/PageHead.vue"
import PageAside from "./bookmark/Mode/PageAside.vue"
import PageFooter from "./bookmark/Mode/PageFooter.vue"
import axios from 'axios'

Vue.prototype.$axios = axios

//引入element-UI组件
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

axios.defaults.baseURL = '/api'


Vue.use(ElementUI)
Vue.config.productionTip = false

Vue.prototype.Log = function (thing , kind){
  this.$axios.get('/Log/add',{
    params: {
              thing:thing,
              kind:kind,
          }
  })
}


Vue.prototype.SearchLog = function (context, kind, is, url){
  this.$axios.get('/search/AddLog',{
    params: {
              context:context,
              kind:kind,
              is:is,
              url:url
          }
  })
}


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


new Vue({
  router,
  render: h => h(MainPage)
}).$mount('#MainPage')


new Vue({
  router,
  render: h => h(PageHead)
}).$mount('#PageHead')


new Vue({
  router,
  render: h => h(PageAside)
}).$mount('#PageAside')


new Vue({
  router,
  render: h => h(PageFooter)
}).$mount('#PageFooter')



