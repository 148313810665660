<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-07-05 20:18:27
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-08-02 06:23:03
 * @FilePath: \webapp\src\webapp\Mode\PageHead.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div style="width: 100%;">
        <el-header>
            <div style="display: inline;float: left">
              <el-button type="primary" style="height: auto; text-align: center; background-color: transparent;">
              </el-button>
            </div>
            <div style="display: inline;">今天也一定要努力摸鱼丫！</div>
            <div style="display: inline;text-align: left; float: left;"><el-button icon="el-icon-upload2" size="mini"  @click="restart">采用备份</el-button></div>
            <div style="display: inline;text-align: right; float: right;">
              <el-upload
              action="none"
              http-request="commit"
              :on-change="commit"
              auto-upload:true
              :on-exceed="handleExceed"
              :file-list="fileList">
              <el-button size="small" type="primary" style="text-align: right; float: right; margin-left: 200px;">点击上传</el-button>
              </el-upload>
            </div>
        </el-header>
    </div>
</template>

<script>

export default {
  data() {
    return {
      timer: '',
      limited: 1,
      dialogVisible: false,
      sName: '',
      sUrl: '',
      tableData: [],
      fileList: [],
      text: '',
      name: ''
    }
  },
  methods: {
    restart() {
      let _this = this;
      this.$axios.get('/bookmark/copy')
        .then(res => {
          let success = res.data.success
          if (success) {
            _this.$message({
              message: '更新成功，请刷新',
              type: "success"
            })
          }

        })
    },
    commit(file) {
      let _this = this;
      let formData = new FormData();
      formData.append("file", file.raw);//拿到存在fileList的文件存放到formData中
      this.$axios.post('/bookmark/send', formData, {
        "Content-Type": "multipart/form-data;charset=utf-8"
      })
        .then(res => {
          let success = res.data.success
          if (success) {
            _this.$message({
              message: '更新成功，请刷新',
              type: "success"
            })
          }

        })
    },
  }
}
</script>


<style scoped>

.el-header{
    background-color: transparent; 
    font-family: "Hiragino Sans GB";
    font-size: 30px;
    /* background-color: #5d99e7; */
    color: #333;
    text-align: center;
    line-height: 60px;
    font-weight: 1000;
  }


#time {
    background-color: #5d99e752;
    text-align: right;
    color: #333;
    width: 120px;
    font-weight: 700;
    }

.el-button {
  border: 0;
  background-color: transparent;
}
    
</style>