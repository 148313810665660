<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-07-05 20:18:27
 * @LastEditors: cosh codeshitmeshit@126.com
 * @LastEditTime: 2023-08-18 22:40:55
 * @FilePath: \webapp\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div id="app" style="height: 99%; width: 99%; position : absolute;">
    <el-container style="height: 99%; width: 99%; position : absolute;">
      <el-header>
        <div id="PageHead" style="height: 15%;"></div>
      </el-header>
          <!-- 主要页面编写 -->
          <el-main style="height: 100%;">
            <div  id="MainPage">
            </div>
          </el-main>
    </el-container>s
  </div>
</template>

<script>
export default {
}
</script>

<style>
#main {
    text-align: center;
}

#test {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 88%;
    width: 88%;
    margin: auto;
}

.el-notification {white-space:pre !important; }

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
