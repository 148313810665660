<!-- eslint-disable vue/no-unused-vars -->
<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-07-05 20:18:27
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-08-03 00:56:39
 * @FilePath: \webapp\src\webapp\MainPage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div style="height: 100%; height: 100%;">
      <div>
    <font style="margin-left: 50px; font-size: x-large; font-weight: 500;" id="NowIp"></font>
    <br><br>
    <div id="test">
        <el-tabs v-model="nowKind" type="card" editable addable="false" @tab-click="handleClick">
        <el-tab-pane
          :key="item.name"
          v-for="(item, index) in editableTabs"
          :label="item.title"
          :name="item.name">
          </el-tab-pane>
        </el-tabs>

        <el-tabs v-model="search" type="card" editable addable="false" @edit="handleTabsEdit">
          <el-tab-pane
            :key="item.name"
            :addable="true"
            v-for="(item, index) in groups"
            :label="item.title"
            :name="item.name">
          </el-tab-pane>
        </el-tabs>

          
        <el-table
            :data="tableData.filter(data => !search || data.groupId.toLowerCase().includes(search.toLowerCase()))"
            style="width: 100%; height: 95%;" 
            height="85%"
            :row-class-name="tableRowClassName">
            <el-table-column
                    min-width="60%">
              <template slot-scope="scope">
                <el-image :src="scope.row.icon" :fit="fit" style="width: 13px; height: 13px"></el-image> <el-link type="primary" @click="Goto(scope.row.url)">{{scope.row.name}}</el-link>
              </template>
              </el-table-column>
            <el-table-column
                prop="groupId"
                label="所属组"
                min-width="20%">
            </el-table-column>
            <el-table-column
              align="center"
              min-width="20%">
              <!-- eslint-disable vue/no-unused-vars -->
              <template slot="header" slot-scope="scope">
                <el-input
                  v-model="search"
                  size="mini"
                  placeholder="筛选分类"/>
              </template>
              <template slot-scope="scope">
                <el-button
                    size="mini"
                    type="primary"
                    @click="JoinGroup(scope.row)">修改组别</el-button>
                <el-button
                  size="mini"
                  type="danger"
                  @click="preDelete(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

  <el-dialog
    title="你正在新建组"
    :visible.sync="addGroupVisible"
    width="20%"
    :before-close="handleClose">
    <el-input v-model="newGroup" placeholder="请输入组名"></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button @click="addGroupVisible = false">取 消</el-button>
      <el-button type="primary" @click="addGroup">确 定</el-button>
    </span>
  </el-dialog>

  <el-dialog
    title="请选择组别"
    :visible.sync="JoinGroupVisible"
    width="30%"
    :before-close="handleClose">
    <el-table
      :data="groups"
      style="width: 100%">
      <el-table-column
        label="编号"
        prop="name">
      </el-table-column>
      <el-table-column
        label="组名"
        prop="title">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="surejoin(scope.row)">加入</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
      
  <el-dialog
    title="提示"
    :visible.sync="deleteGroupVisible"
    width="30%"
    :before-close="handleClose">
    <h3>你正在删除一个分类,请确定</h3>
    <br><br>
    <el-input v-model="password" placeholder="请输入PID"  style="width: 200px" @keypress.enter.native="deleteGroup" show-password></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button @click="deleteGroupVisible = false">取 消</el-button>
      <el-button type="primary" @click="deleteGroup">确 定</el-button>
    </span>
  </el-dialog>
  </div>
</template>


<script>
export default {
  mounted() {
    this.$axios("/bookmark/index").then(res => {
      if (res.data.success) {
        for (let i = 0; i < res.data.data.kinds.length; i++) {
          this.editableTabs.push({
            title: res.data.data.kinds[i].name,
            name: res.data.data.kinds[i].kindId
          })
        }
      }
      else {
        this.$message.error(res.data.errorMsg);
      }
    })
    this.select(0)
  },
  data() {
    return {
      deleteGroupVisible : false,
      JoinGroupVisible : false,
      addGroupVisible : false,
      tableData: [],
      search: "0",
      nowKind: '0',
      editableTabs: [],
      groups: [],
      tabIndex: '0',
      newGroup: '',
      chooseGroup: '',
      joinItem: [],
      deleted: [],
      password : ''
    };
  },
  methods: {
    deleteGroup()
    {
      let _this = this
      this.$axios("/bookmark/deleteGroup", {
        params: {
          Id: this.deleted,
          password: this.password
        }
      }).then(res => {
        _this.password = ''
        let success = res.data.success
        if (success) {
          this.$message({
            message: '添加成功',
            type: 'success'
          });
          _this.search = 0
          _this.select(_this.nowKind)
        }
        else {
          this.$message.error(res.data.errorMsg);
        }
        _this.deleteGroupVisible = false
      })
    },
    surejoin(row)
    {
      let _this = this
      this.$axios("/bookmark/joinGroup", {
        params: {
          name: this.joinItem.name,
          url: this.joinItem.url,
          groupId: row.name
        }
      }).then(res => {
        let success = res.data.success
        if (success) {
          this.$message({
            message: '添加成功',
            type: 'success'
          });
          _this.search = 0
          _this.select(_this.nowKind)
        }
        else {
          this.$message.error(res.data.errorMsg);
        }
        _this.JoinGroupVisible = false
      })
    },
    JoinGroup(row)
    {
      this.joinItem = row
      this.JoinGroupVisible = true
    },
    handleTabsEdit(targetName, action) {
      if (action === 'add') {this.addGroupVisible = true}
      if (action === 'remove') {
        this.deleted = targetName
        this.deleteGroupVisible = true
      }
    },
    addGroup()
    {
      let _this = this
      this.$axios("/bookmark/addGroup", {
        params: {
          groupName: this.newGroup,
          kindId : this.nowKind
        }
      }).then(res => {
        let success = res.data.success
        if (success) {
          this.$message({
            message: '添加成功',
            type: 'success'
          });
          _this.search = 0
          _this.select(_this.nowKind)
        }
        else {
          this.$message.error(res.data.errorMsg);
        }
        _this.addGroupVisible=false
      })
    },
    Goto(url) {
      location.href = url
    },
    preDelete(row) {
      let _this = this
      this.$confirm('你正在将一个书签拉入黑名单', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.delete(row)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    delete(row) {
      let _this = this
      this.$axios("/bookmark/delete", {
        params: {
          name: row.name,
          url: row.url
        }
      }).then(res => {
        let success = res.data.success
        if (success) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          _this.tabIndex = 0
          _this.select(0)
        }
        else {
          this.$message.error(res.data.errorMsg);
        }
      })
    },
    handleClick(tab) {
      this.select(tab.name)
    },
    select(target) {
      let _this = this
      this.$axios.get("/bookmark/select?kindId=" + target).then(res => {
        if (res.data.success) {
          _this.tableData = res.data.data.items
          _this.groups=[]
          for (let i = 0; i < res.data.data.groups.length; i++) {
            _this.groups.push({
              title: res.data.data.groups[i].name,
              name: res.data.data.groups[i].groupId
            })
          }
          _this.search = 0
        }
        else {
          this.$message.error(res.data.errorMsg)
        }
      })
    }
  }
}
</script>

<style>
#main {
  text-align: center;
}

#test {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 88%;
  width: 88%;
  margin: auto;
}

.el-notification {
  white-space: pre !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
